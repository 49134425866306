import * as React from 'react';
export const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 15H4c-.5523 0-1-.4477-1-1V3c0-.5523.4477-1 1-1h8c.5523 0 1 .4477 1 1v1"
    />
    <rect
      width={10}
      height={13}
      x={9}
      y={7}
      stroke="currentColor"
      strokeWidth={2}
      rx={1}
    />
  </svg>
);
