import * as React from 'react';
export const BriefcaseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={18}
      height={6}
      x={2}
      y={6}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={2}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12h16v5c0 1.1045-.8955 2-2 2H5c-1.1046 0-2-.8955-2-2v-5ZM13.9866 3.2978A2 2 0 0 0 12.1139 2H9.886a2 2 0 0 0-1.8727 1.2978L7 6.0004h8l-1.0134-2.7026ZM11 10.0003v4"
    />
  </svg>
);
