import * as React from 'react';
export const CameraIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="m9.1056 4.7889-.8774-.4387.8774.4386Zm-.3129.6257v.981a.981.981 0 0 0 .8774-.5423l-.8774-.4387Zm6.4146 0-.8774.4387a.9808.9808 0 0 0 .8774.5423v-.981Zm-.3129-.6258-.8774.4388.8774-.4387Zm-4.9114.4388A2.255 2.255 0 0 1 12 3.981V2.019a4.217 4.217 0 0 0-3.7718 2.3312l1.7548.8774Zm-.3129.6257.3129-.6257-1.7548-.8774-.3129.6257 1.7548.8774Zm-5.7081.5423h4.8307v-1.962H3.962v1.962Zm-.981.981c0-.5419.4392-.981.981-.981v-1.962c-1.6254 0-2.943 1.3176-2.943 2.943h1.962Zm0 9.349v-9.349H1.019v9.349h1.962Zm.981.981c-.5418 0-.981-.4392-.981-.981H1.019c0 1.6253 1.3176 2.9429 2.943 2.9429v-1.9619Zm13.0761 0H3.9619v1.9619h13.0762v-1.9619Zm.9809-.981c0 .5418-.4392.981-.9809.981v1.9619c1.6253 0 2.9429-1.3176 2.9429-2.9429h-1.962Zm0-9.349v9.349h1.962v-9.349h-1.962Zm-.9809-.981c.5417 0 .9809.4391.9809.981h1.962c0-1.6254-1.3176-2.943-2.9429-2.943v1.962Zm-1.8308 0h1.8308v-1.962h-1.8308v1.962Zm-1.1903-1.168.3129.6257 1.7548-.8774-.3129-.6258-1.7548.8775ZM12 3.981a2.255 2.255 0 0 1 2.017 1.2466l1.7548-.8774A4.217 4.217 0 0 0 12 2.019v1.962Z"
    />
    <circle
      cx={12}
      cy={12}
      r={2.5}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.962}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.962}
      d="M5.5 9h1"
    />
  </svg>
);
