import * as React from 'react';
export const WalletCoinsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={18}
      height={14}
      x={2}
      y={6}
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={2}
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M11 12c0-1.1046.8954-2 2-2h7v6h-7c-1.1046 0-2-.8954-2-2v-2ZM11 6a2.9994 2.9994 0 0 0-.8787-2.1213 2.9997 2.9997 0 0 0-4.2426 0A3 3 0 0 0 5 6"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M15.9836 5.6863a2.9998 2.9998 0 0 0-3.8487-3.521 2.9999 2.9999 0 0 0-2.0141 2.247"
    />
  </svg>
);
