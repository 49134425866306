import * as React from 'react';
export const MicroCheckMiniForLabelIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m12 4-6 7-3.5-3.5"
    />
  </svg>
);
