import * as React from 'react';
export const CheckmarkDashedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle cx={11} cy={11} r={9.5} fill="#F8F9FB" />
    <path
      fill="#BFC1C6"
      fillRule="evenodd"
      d="M9.0489 1.1903A10.0472 10.0472 0 0 1 11 1c.6672 0 1.3197.0654 1.9511.1903l-.1848.9343A9.0948 9.0948 0 0 0 11 1.9524a9.0943 9.0943 0 0 0-1.7663.1722l-.1848-.9343Zm7.5076 1.4943a10.0532 10.0532 0 0 1 2.7589 2.759l-.7914.5297a9.1008 9.1008 0 0 0-2.4973-2.4973l.5298-.7914ZM2.6846 5.4436a10.0522 10.0522 0 0 1 2.759-2.759l.5297.7914A9.0999 9.0999 0 0 0 3.476 5.9733l-.7914-.5298Zm18.1251 3.6053A10.048 10.048 0 0 1 21 11c0 .6672-.0654 1.3197-.1903 1.9511l-.9343-.1848A9.0971 9.0971 0 0 0 20.0476 11a9.0966 9.0966 0 0 0-.1722-1.7663l.9343-.1848ZM1 11c0-.6672.0654-1.3197.1903-1.9511l.9343.1848A9.0943 9.0943 0 0 0 1.9524 11c0 .6049.0593 1.1954.1722 1.7663l-.9343.1848A10.047 10.047 0 0 1 1 11Zm18.3154 5.5565a10.0542 10.0542 0 0 1-2.7589 2.7589l-.5298-.7914a9.1019 9.1019 0 0 0 2.4973-2.4973l.7914.5298ZM5.4435 19.3154a10.0532 10.0532 0 0 1-2.7589-2.7589l.7914-.5298a9.101 9.101 0 0 0 2.4973 2.4973l-.5298.7914ZM11 21a10.048 10.048 0 0 1-1.9511-.1903l.1848-.9343A9.0966 9.0966 0 0 0 11 20.0476a9.0971 9.0971 0 0 0 1.7663-.1722l.1848.9343A10.0478 10.0478 0 0 1 11 21Z"
      clipRule="evenodd"
    />
  </svg>
);
