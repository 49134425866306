import * as React from 'react';
export const MicroHourglassMiniForLabelsIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.5 1.5h7"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M4.5 3.5C4.5 5.458 7 7 7 7s2.5-1.542 2.5-3.5M9.5 10.5C9.5 8.542 7 7 7 7s-2.5 1.542-2.5 3.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.5 12.5h7"
    />
  </svg>
);
