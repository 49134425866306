import * as React from 'react';
export const StationaryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 19c0 .5523-.4477 1-1 1h-2c-.5523 0-1-.4477-1-1V7.21A3.9996 3.9996 0 0 1 15.67 5L17 3l1.33 2A3.9996 3.9996 0 0 1 19 7.21V19Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 17h-4v2h4v-2ZM8 3H5c-1.1046 0-2 .8954-2 2v13c0 1.1046.8954 2 2 2h3c1.1046 0 2-.8954 2-2V5c0-1.1046-.8954-2-2-2ZM3 7h3M3 11.5h3M3 16h3"
    />
  </svg>
);
