import * as React from 'react';
export const LockIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={16}
      height={10}
      x={3}
      y={10}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={3}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 9V6c0-2.2091-1.7909-4-4-4v0C8.7909 2 7 3.7909 7 6v3M11 14v2"
    />
  </svg>
);
