import * as React from 'react';
export const BreadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1.5 9.3479V6.9217C1.5 4.8504 5.1796 2.5 11.3441 2.5 17.5086 2.5 20.5 4.9217 20.5 6.9217v2.4262c0 1.1045-.8966 2-2.0026 2h-.4882v5.1169c0 1.6569-1.3449 3-3.0039 3H6.9948c-1.659 0-3.004-1.3431-3.004-3v-5.1169h-.4882c-1.106 0-2.0026-.8954-2.0026-2ZM14.6016 8.9063l-4.9744 4.9746M10.1838 7.75 8.3772 9.5567"
    />
  </svg>
);
