import * as React from 'react';
export const PaperIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 20c-1.1046 0-2-.8954-2-2V8.6569a4 4 0 0 1 1.1716-2.8285l2.6568-2.6568A4 4 0 0 1 9.657 2H17c1.1046 0 2 .8954 2 2v14c0 1.1046-.8954 2-2 2H5Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M8 7.5H4.6213L8.5 3.6213V7a.5.5 0 0 1-.5.5Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 12h8M7 16h4"
    />
  </svg>
);
