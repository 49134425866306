import * as React from 'react';
export const FoodCutleryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 6V2M6 20V10M10 2v4a4 4 0 0 1-8 0V2M15 20V2a4 4 0 0 1 4 4v1.8769C19 10.3 17.3508 12.4123 15 13v0"
    />
  </svg>
);
