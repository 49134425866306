import * as React from 'react';
export const XForNoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5.5752 16.4248 16.4248 5.5752M16.4248 16.4248 5.5752 5.5752"
    />
  </svg>
);
