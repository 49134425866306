import * as React from 'react';
export const GraphUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 18.5v-1c0-.8284-.6716-1.5-1.5-1.5S2 16.6716 2 17.5v1c0 .8284.6716 1.5 1.5 1.5S5 19.3284 5 18.5ZM12 18.5v-6c0-.8284-.6716-1.5-1.5-1.5S9 11.6716 9 12.5v6c0 .8284.6716 1.5 1.5 1.5s1.5-.6716 1.5-1.5ZM19 18.5v-12c0-.8284-.6716-1.5-1.5-1.5S16 5.6716 16 6.5v12c0 .8284.6716 1.5 1.5 1.5s1.5-.6716 1.5-1.5ZM3 11l7-7M7 3h4v4"
    />
  </svg>
);
