import * as React from 'react';
export const TrashcanIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.8947 5.7895V17c0 1.6569 1.3432 3 3 3h8.2106c1.6568 0 3-1.3431 3-3V5.7894M2 5.7895h18M8.6316 4.8421V3c0-.5523.4477-1 1-1h2.7368c.5523 0 1 .4477 1 1v1.8421M9.1053 10.5263v4.7369M12.8947 10.5263v4.7369"
    />
  </svg>
);
