import * as React from 'react';
export const BanknoteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="m3.2726 15.3319-.1164.0611C2.6306 15.6689 2 15.2877 2 14.6941v-9.775a.4866.4866 0 0 1 .2604-.4308l1.0122-.5314A9.9502 9.9502 0 0 1 11 3.3125a9.9503 9.9503 0 0 0 7.7274-.6444l.1164-.061C19.3694 2.331 20 2.7122 20 3.3058v9.775a.4866.4866 0 0 1-.2604.4308l-1.0122.5314A9.9503 9.9503 0 0 1 11 14.6875a9.9502 9.9502 0 0 0-7.7274.6444Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M19 14v5.0666c0 .3821-.4379.5985-.7415.3664l-1.226-.9375c-3.804-2.909-9.1282-2.7459-12.747.3904-.503.436-1.2855.0787-1.2855-.5869V15"
    />
    <circle
      cx={11}
      cy={9}
      r={2.25}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <circle cx={17} cy={6} r={1} fill="currentColor" />
    <circle cx={5} cy={6} r={1} fill="currentColor" />
    <circle cx={17} cy={11} r={1} fill="currentColor" />
    <circle cx={5} cy={11} r={1} fill="currentColor" />
  </svg>
);
