import * as React from 'react';
export const LeversAltIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.5 2.5C17.433 2.5 19 4.067 19 6s-1.567 3.5-3.5 3.5S12 7.933 12 6s1.567-3.5 3.5-3.5ZM6.5 12.5c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5S3 17.933 3 16s1.567-3.5 3.5-3.5Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 16c0-1.6569-1.3431-3-3-3H8l2 2h7c.5523 0 1 .4477 1 1s-.4477 1-1 1h-7l-2 2h9c1.6569 0 3-1.3431 3-3ZM2 6c0 1.6569 1.3432 3 3 3h9l-2-2H5c-.5523 0-1-.4477-1-1s.4477-1 1-1h7l2-2H5C3.3432 3 2 4.3431 2 6Z"
      clipRule="evenodd"
    />
  </svg>
);
