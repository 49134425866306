import * as React from 'react';
export const PencilLinesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.9927 7.704c.3902-.3892.3921-1.0207.0041-1.412l-1.6765-1.6917c-.39-.3935-1.0256-.395-1.4174-.0032l-9.1555 9.1555a4.3634 4.3634 0 0 0-1.1879 2.2215l-.5168 2.5686 2.5687-.5168a4.3628 4.3628 0 0 0 2.2215-1.1879l9.1598-9.134ZM9 4H3M5 8H3"
    />
    <path
      fill="currentColor"
      d="m15.7158 4.023 3.8565 3.8565-2.3143 2.3144-3.8566-3.8566z"
    />
  </svg>
);
