import * as React from 'react';
export const KeyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.5591 3h-2.463a.616.616 0 0 0-.4236.1686l-5.0896 4.8011a.6158.6158 0 0 1-.5862.1453 5.5433 5.5433 0 0 0-3.5578.2199 5.541 5.541 0 0 0-2.6878 2.3405 5.5383 5.5383 0 0 0 .8628 6.6928 5.5429 5.5429 0 0 0 6.6908.894 5.54 5.54 0 0 0 2.3541-2.6758 5.538 5.538 0 0 0 .2367-3.5555.6149.6149 0 0 1 .1589-.5958l4.9406-4.9242a.6156.6156 0 0 0 .1798-.4333v-2.462A.6157.6157 0 0 0 18.5591 3Z"
    />
    <circle cx={8.5} cy={13.5} r={1.5} fill="currentColor" />
  </svg>
);
