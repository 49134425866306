import * as React from 'react';
export const SnowflakeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 1c.5523 0 1 .4975 1 1.1111V19.889C12 20.5025 11.5523 21 11 21s-1-.4975-1-1.1111V2.1111C10 1.4975 10.4477 1 11 1Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M9.4472 3.1056c-.494-.247-1.0946-.0468-1.3416.4472-.247.494-.0468 1.0946.4472 1.3416l.8944-1.7888Zm4 1.7888c.494-.247.6942-.8476.4472-1.3416-.247-.494-.8476-.6942-1.3416-.4472l.8944 1.7888Zm-4.8944 0 2 1 .8944-1.7888-2-1-.8944 1.7888Zm2.8944 1 2-1-.8944-1.7888-2 1 .8944 1.7888ZM10 5v13h2V5h-2Z"
    />
    <path
      fill="currentColor"
      d="M12.1838 17.4487c.5239.1746 1.0902-.1085 1.2649-.6325.1746-.5239-.1085-1.0902-.6325-1.2649l-.6324 1.8974Zm-3-1.8974c-.524.1747-.8071.741-.6325 1.2649.1747.524.741.8071 1.265.6325l-.6325-1.8974Zm3.6324 0-1.5-.5-.6324 1.8974 1.5.5.6324-1.8974Zm-2.1324-.5-1.5.5.6324 1.8974 1.5-.5-.6324-1.8974ZM12 16V6h-2v10h2Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.3397 16c-.2761-.4783-.0691-1.1148.4623-1.4216l15.396-8.8889c.5314-.3068 1.1861-.1678 1.4623.3105.2761.4783.0691 1.1148-.4623 1.4216L3.802 16.3105c-.5314.3068-1.1861.1678-1.4623-.3105Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M4.9396 16.292c.0331.5513.5068.9713 1.0581.9383.5513-.0331.9714-.5069.9383-1.0582l-1.9964.1199Zm-.4508-4.3586c-.4609-.3043-1.0812-.1773-1.3855.2836-.3043.4609-.1774 1.0812.2835 1.3855l1.102-1.6691Zm2.4472 4.2387-.134-2.232-1.9964.1198.134 2.2321 1.9964-.1199Zm-.5812-3.0066-1.866-1.2321-1.102 1.6691 1.866 1.232 1.102-1.669Zm-.051 1.7005 11.2584-6.5-1-1.732-11.2584 6.5 1 1.732Z"
    />
    <path
      fill="currentColor"
      d="M15.9928 6.7505c-.1107-.541-.6391-.89-1.1801-.7792-.5411.1107-.89.639-.7793 1.1801l1.9594-.401Zm-.1431 3.5467c.4132.3665 1.0452.3285 1.4116-.0847.3665-.4132.3285-1.0452-.0847-1.4117l-1.3269 1.4964Zm-1.8163-3.1458.317 1.549 1.9594-.4009-.317-1.549-1.9594.401Zm.6333 2.0968 1.183 1.049 1.3269-1.4964-1.183-1.049-1.3269 1.4964Zm.1634-1.6142-8.6602 5 1 1.732 8.6602-5-1-1.732Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.6603 16c.2761-.4783.0691-1.1148-.4623-1.4216L3.802 5.6895C3.2706 5.3827 2.6159 5.5217 2.3397 6c-.2761.4783-.0691 1.1148.4623 1.4216l15.396 8.8889c.5314.3068 1.1861.1678 1.4623-.3105Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M17.0604 16.292c-.0331.5513-.5068.9713-1.0581.9383-.5513-.0331-.9714-.5069-.9383-1.0582l1.9964.1199Zm.4508-4.3586c.4609-.3043 1.0812-.1773 1.3855.2836.3043.4609.1774 1.0812-.2835 1.3855l-1.102-1.6691Zm-2.4472 4.2387.134-2.232 1.9964.1198-.134 2.2321-1.9964-.1199Zm.5812-3.0066 1.866-1.2321 1.102 1.6691-1.8661 1.232-1.1019-1.669Zm.051 1.7005-11.2584-6.5 1-1.732 11.2584 6.5-1 1.732Z"
    />
    <path
      fill="currentColor"
      d="M6.0072 6.7505c.1107-.541.639-.89 1.1801-.7792.5411.1107.89.639.7793 1.1801l-1.9594-.401Zm.1431 3.5467c-.4132.3665-1.0452.3285-1.4116-.0847-.3665-.4132-.3285-1.0452.0847-1.4117l1.327 1.4964Zm1.8163-3.1458-.317 1.549-1.9594-.4009.317-1.549 1.9594.401Zm-.6333 2.0968-1.183 1.049-1.327-1.4964 1.1831-1.049 1.327 1.4964ZM7.17 7.634l8.6602 5-1 1.732-8.6602-5 1-1.732Z"
    />
  </svg>
);
