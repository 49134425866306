import * as React from 'react';
export const MegaphoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m11 6 6.1877-2.7501C18.5103 2.6621 20 3.6302 20 5.0775v9.845c0 1.4473-1.4897 2.4154-2.8123 1.8276L11 14M2 8c0-1.1046.8954-2 2-2h6.9048v8H4c-1.1046 0-2-.8954-2-2V8ZM3.9999 14h4.4524l.9029 3.5005C9.6817 18.7663 8.7259 20 7.4186 20h-.3571a2 2 0 0 1-1.9403-1.5149L3.9999 14Z"
    />
  </svg>
);
