import * as React from 'react';
export const CoffeeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 10h12v4a6 6 0 0 1-12 0v-4ZM15 10h2.5a2.5 2.5 0 0 1 0 5H15v-5Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 20h14M7 6V4M11 6V2"
    />
  </svg>
);
