import * as React from 'react';
export const ScreenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 3H4c-1.1046 0-2 .8954-2 2v9c0 1.1046.8954 2 2 2h14c1.1046 0 2-.8954 2-2V5c0-1.1046-.8954-2-2-2ZM7 19h8M8 12H6"
    />
  </svg>
);
