import * as React from 'react';
export const GlowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.1111 10.5833C17.2222 9.5 20 9.5 20 9.5s-2.7729.0048-3.8889-1.0833C15 7.3333 15 3 15 3s0 4.3333-1.1111 5.4167C12.7778 9.5 10 9.5 10 9.5s2.7778 0 3.8889 1.0833C15 11.6667 15 16 15 16s0-4.3333 1.1111-5.4167ZM9.0556 16.5C9.611 16 11 16 11 16s-1.3864.0022-1.9444-.5C8.5 15 8.5 13 8.5 13s0 2-.5556 2.5C7.389 16 6 16 6 16s1.3889 0 1.9444.5C8.5 17 8.5 19 8.5 19s0-2 .5556-2.5ZM6.0556 7.5C6.611 7 8 7 8 7s-1.3864.0022-1.9444-.5C5.5 6 5.5 4 5.5 4s0 2-.5556 2.5C4.389 7 3 7 3 7s1.3889 0 1.9444.5C5.5 8 5.5 10 5.5 10s0-2 .5556-2.5Z"
    />
  </svg>
);
