import * as React from 'react';
export const SuitcaseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={16}
      height={12}
      x={3}
      y={6}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={2}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18v2M16 18v2M14 5V3c0-.5523-.4477-1-1-1H9c-.5523 0-1 .4477-1 1v2M3 11h16"
    />
  </svg>
);
