import * as React from 'react';
export const FurnitureIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 20v-2M15 20v-2M4 10 2.5463 4.912C2.2725 3.954 2.992 3 3.9886 3h14.0228c.9966 0 1.7161.9539 1.4423 1.912L18 10M5 18l-2.4933-6.6489C2.2615 10.6974 2.7447 10 3.443 10h1.864a1 1 0 0 1 .9363.6489L7.5 14h7l1.2567-3.3511A1 1 0 0 1 16.693 10h1.864c.6982 0 1.1815.6974.9363 1.3511L17 18H5Z"
    />
  </svg>
);
