import * as React from 'react';
export const CalculatorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={17}
      height={17.244}
      x={2.413}
      y={2.432}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={3}
    />
    <rect
      width={2.5}
      height={2.5}
      x={5.663}
      y={10.054}
      fill="currentColor"
      rx={1.25}
    />
    <rect
      width={2.5}
      height={2.5}
      x={9.663}
      y={10.054}
      fill="currentColor"
      rx={1.25}
    />
    <rect
      width={2.5}
      height={2.5}
      x={13.663}
      y={10.054}
      fill="currentColor"
      rx={1.25}
    />
    <rect
      width={2.5}
      height={2.5}
      x={5.663}
      y={14.054}
      fill="currentColor"
      rx={1.25}
    />
    <rect
      width={2.5}
      height={2.5}
      x={9.663}
      y={14.054}
      fill="currentColor"
      rx={1.25}
    />
    <rect
      width={2.5}
      height={2.5}
      x={13.663}
      y={14.054}
      fill="currentColor"
      rx={1.25}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.5309 6.9632h8.6942"
    />
  </svg>
);
