import * as React from 'react';
export const MagnifyingGlassIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M13.5355 6.4645c1.9527 1.9526 1.9527 5.1184 0 7.071-1.9526 1.9527-5.1184 1.9527-7.071 0-1.9527-1.9526-1.9527-5.1184 0-7.071 1.9526-1.9527 5.1184-1.9527 7.071 0Z"
    />
    <path
      fill="currentColor"
      d="m14.5961 13.182-.7071-.7071-1.4142 1.4142.7071.7071 1.4142-1.4142Zm2.4749 5.3033c.3905.3905 1.0237.3905 1.4142 0 .3906-.3905.3906-1.0237 0-1.4142l-1.4142 1.4142Zm-3.8891-3.8891 3.8891 3.8891 1.4142-1.4142-3.8891-3.8891-1.4142 1.4142Z"
    />
  </svg>
);
