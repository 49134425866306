import * as React from 'react';
export const AirplaneUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14.7405 9.9771 3.0627 6.1096a1.7303 1.7303 0 0 1-.4733 2.1325.4327.4327 0 0 1-.5716-.0307l-5.133-5.0437-2.604 2.5996.9644 2.2433a.9623.9623 0 0 1-.27 1.1169L8.8186 20l-2.0446-2.0411-2.7294-2.7248L2 13.1929l.9066-.905a.9658.9658 0 0 1 1.061-.2022l2.276.9628 2.5751-2.5707-5.177-5.1682a.45.45 0 0 1-.0369-.5953 1.7999 1.7999 0 0 1 2.2075-.5109l6.218 3.0297 3.6746-3.6683A1.9296 1.9296 0 0 1 17.0696 3a1.9292 1.9292 0 0 1 1.3647.5648A1.925 1.925 0 0 1 19 4.9272a1.9223 1.9223 0 0 1-.5657 1.3624L14.7405 9.977Z"
    />
  </svg>
);
