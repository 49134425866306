import * as React from 'react';
export const LinkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.4233 10.91c-.6667-.6667-1.2295-.1039-1.344.0106l-2.0317 2.0317C1.3644 14.6355 1.3431 17.3431 3 19c1.6568 1.6568 4.3644 1.6355 6.0476-.0477l3.8095-3.8095c1.6832-1.6831 1.7045-4.3907.0476-6.0476 0 0-.6666-.6667-1.3439.0106-.6772.6772-.0106 1.3439-.0106 1.3439.9205.9205.9087 2.4247-.0264 3.3598L7.7143 17.619c-.9351.9351-2.4394.9469-3.3598.0265-.9205-.9205-.9087-2.4247.0264-3.3598l2.0318-2.0318c.116-.1159.6772-.6772.0106-1.3439Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.5767 11.0899c.6667.6667 1.2295.1039 1.344-.0106l2.0317-2.0317C20.6356 7.3644 20.6569 4.6568 19 3c-1.6568-1.6569-4.3644-1.6356-6.0476.0476L9.1429 6.8571c-1.6832 1.6832-1.7045 4.3908-.0476 6.0476 0 0 .6666.6667 1.3439-.0105.6772-.6773.0106-1.344.0106-1.344-.9205-.9204-.9087-2.4247.0264-3.3598l3.8095-3.8095c.9351-.935 2.4394-.9469 3.3598-.0264.9205.9204.9087 2.4247-.0264 3.3598L15.5873 9.746c-.1159.116-.6772.6773-.0106 1.3439Z"
      clipRule="evenodd"
    />
  </svg>
);
