import * as React from 'react';
export const DropIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.2924 17.2094a7.0083 7.0083 0 0 1-1.6937-2.3508 7.0152 7.0152 0 0 1 0-5.6718 7.0076 7.0076 0 0 1 1.6937-2.3507L11 2.5625l4.7076 4.2736a7.0082 7.0082 0 0 1 1.6937 2.3507 7.0147 7.0147 0 0 1-1.6937 8.0226v0A6.9946 6.9946 0 0 1 11 19.0313a6.9947 6.9947 0 0 1-4.7076-1.8219v0Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.2032 15.1922a3.3785 3.3785 0 0 1-3.4663-.6597v0a3.3656 3.3656 0 0 1-.825-1.1333 3.3689 3.3689 0 0 1-.2922-1.3716"
    />
  </svg>
);
