import * as React from 'react';
export const ClippyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M8 13v3.5c0 1.933 1.567 3.5 3.5 3.5v0c1.933 0 3.5-1.567 3.5-3.5v-11C15 3.567 13.433 2 11.5 2v0C9.567 2 8 3.567 8 5.5V9"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M11.9088 8v5.5456c0 1.0794-.875 1.9544-1.9544 1.9544v0C8.875 15.5 8 14.625 8 13.5456V13"
    />
  </svg>
);
