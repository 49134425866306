import * as React from 'react';
export const GreenCompleteCheckIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#00B683"
      fillOpacity={0.16}
      d="M11 21c5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10C5.4771 1 1 5.4771 1 11c0 5.5228 4.4771 10 10 10Z"
    />
    <path
      stroke="#007F5C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14.9999 8-5 6-3-3"
    />
  </svg>
);
