import * as React from 'react';
export const PaperPrintTearIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16 19-.4081.9129L17 20.5424V19h-1Zm-2.5-1.1176.4081-.913a.9999.9999 0 0 0-.8162 0l.4081.913ZM11 19l-.4081.9129.4081.1825.4081-.1825L11 19Zm-2.5-1.1176.4081-.913a1 1 0 0 0-.8162 0l.4081.913ZM6 19H5v1.5424l1.4081-.6295L6 19ZM7 3c0-.5523-.4477-1-1-1s-1 .4477-1 1h2Zm10 0c0-.5523-.4477-1-1-1s-1 .4477-1 1h2Zm-.5919 15.0871-2.5-1.1177-.8162 1.8259 2.5 1.1176.8162-1.8258Zm-3.3162-1.1177-2.5 1.1177.8162 1.8258 2.5-1.1176-.8162-1.8259Zm-1.6838 1.1177-2.5-1.1177-.8162 1.8259 2.5 1.1176.8162-1.8258Zm-3.3162-1.1177-2.5 1.1177.8162 1.8258 2.5-1.1176-.8162-1.8259ZM7 19V3H5v16h2Zm8-16v16h2V3h-2Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 10h4M9 13h2"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M5 6H3.5C2.6716 6 2 5.3284 2 4.5S2.6716 3 3.5 3h15c.8284 0 1.5.6716 1.5 1.5S19.3284 6 18.5 6H17"
    />
  </svg>
);
