import * as React from 'react';
export const PencilIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.4927 6.8171c.3902-.3891.3921-1.0206.0041-1.412l-1.6765-1.6917c-.39-.3935-1.0256-.3949-1.4174-.0031l-9.1555 9.1554a4.364 4.364 0 0 0-1.1879 2.2216l-.5168 2.5686 2.5687-.5168a4.3636 4.3636 0 0 0 2.2215-1.1879l9.1598-9.134Z"
    />
    <path
      fill="currentColor"
      d="m15.2159 3.136 3.8565 3.8566-2.3143 2.3144-3.8566-3.8566z"
    />
  </svg>
);
