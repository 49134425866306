import * as React from 'react';
export const PaperScrollIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 16V3a1.0002 1.0002 0 0 0-1-1H3a1 1 0 0 0-1 1v14.67A2.33 2.33 0 0 0 4.33 20H5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 17.5c0 .663-.2634 1.2989-.7322 1.7678A2.5004 2.5004 0 0 1 17.5 20h-13A2.5001 2.5001 0 0 0 7 17.5V17a1 1 0 0 1 1-1h11a1.0001 1.0001 0 0 1 1 1v.5Z"
    />
  </svg>
);
