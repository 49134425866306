import * as React from 'react';
export const BuildingLocationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 20H4c-1.1046 0-2-.8955-2-2V4c0-1.1046.8954-2 2-2h9c1.1046 0 2 .8954 2 2V6.998"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m15 20-3.89-5.46a4.7804 4.7804 0 0 1 .51-6.16v0A4.7812 4.7812 0 0 1 15 6.98a4.7803 4.7803 0 0 1 3.38 1.4v0a4.7801 4.7801 0 0 1 .51 6.16L15 20Z"
    />
    <circle cx={15} cy={12} r={0.5} stroke="currentColor" />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 6h2M5 10h2M5 14h2"
    />
  </svg>
);
