import * as React from 'react';
export const ScissorsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle cx={15} cy={17} r={2} stroke="currentColor" strokeWidth={2} />
    <path
      fill="currentColor"
      d="M5.8475 4.4156c-.468-.7497-.2393-1.7368.5107-2.2042a.3999.3999 0 0 1 .5507.1276L14.5 14.5l-1.6973 1.0579L5.8475 4.4156Z"
    />
    <circle
      cx={3}
      cy={3}
      r={2}
      stroke="currentColor"
      strokeWidth={2}
      transform="matrix(-1 0 0 1 10 14)"
    />
    <path
      fill="currentColor"
      d="M16.1525 4.4156c.468-.7497.2393-1.7368-.5107-2.2042a.3999.3999 0 0 0-.5507.1276L7.5 14.5l1.6973 1.058 6.9552-11.1424Z"
    />
  </svg>
);
