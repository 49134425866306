import * as React from 'react';
export const LoopRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.9453 2.8142v4.3913h-4.5432"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.5912 11.5043c-.305 4.1925-3.951 7.344-8.1435 7.039-4.1926-.3051-7.344-3.9511-7.039-8.1436.305-4.1926 3.951-7.344 8.1436-7.039 2.6177.1904 4.8295 1.6834 6.0476 3.7985"
    />
  </svg>
);
