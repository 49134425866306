import * as React from 'react';
export const FastFoodIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 13a3.9996 3.9996 0 0 1 2.8284 1.1716A3.9996 3.9996 0 0 1 19 17h-8a3.9996 3.9996 0 0 1 1.1716-2.8284A3.9996 3.9996 0 0 1 15 13Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 20h8M12.71 10.25 13 7H3l1 13h3M8 7V2L5 3M4 16h3"
    />
  </svg>
);
