import * as React from 'react';
export const LockOpenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={14}
      height={10}
      x={5}
      y={10}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={3}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 6.5v-1C2 3.567 3.567 2 5.5 2v0C7.433 2 9 3.567 9 5.5V9M12 14v2"
    />
  </svg>
);
