import * as React from 'react';
export const ParkingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 2H5c-1.1046 0-2 .8954-2 2v10c0 1.1046.8954 2 2 2h12c1.1046 0 2-.8954 2-2V4c0-1.1046-.8954-2-2-2ZM11 16v4"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12V6h2a2 2 0 0 1 0 4H9.5625"
    />
  </svg>
);
