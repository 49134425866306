import * as React from 'react';
export const ImageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={16}
      height={15}
      x={3}
      y={4}
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={2}
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m4.0625 19 3.778-3.8259a2 2 0 0 1 2.5325-.2588L12 16l1.6164-2.1552c.7302-.9737 2.1536-1.0748 3.0142-.2142l2.6819 2.6819"
    />
    <circle
      cx={8.594}
      cy={9.281}
      r={1.719}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);
