import * as React from 'react';
export const NightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.2446 13.953a9.1641 9.1641 0 0 1-7.3982-3.7606A9.1632 9.1632 0 0 1 6.5192 2a9.1633 9.1633 0 1 0 13.3783 10.6701 9.163 9.163 0 0 1-4.6529 1.2829Z"
    />
    <circle cx={12} cy={3} r={0.5} stroke="currentColor" />
    <circle cx={15} cy={7} r={0.5} stroke="currentColor" />
    <circle cx={18} cy={3} r={0.5} stroke="currentColor" />
  </svg>
);
