import * as React from 'react';
export const CompleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle cx={11} cy={11} r={8} fill="#00B683" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m7.5 11 2.6251 2.5 4.375-5"
    />
  </svg>
);
