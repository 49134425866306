import * as React from 'react';
export const PirateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 20c4.9706 0 9-4.0294 9-9s-4.0294-9-9-9-9 4.0294-9 9 4.0294 9 9 9Z"
    />
    <circle cx={8} cy={10} r={1} fill="currentColor" />
    <circle cx={8} cy={10} r={1} stroke="currentColor" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M10 15h2"
    />
    <path
      fill="currentColor"
      d="M17.1849 9.172c-.2389 1.5967-1.7268 2.6974-3.3235 2.4586-1.5967-.2388-2.6975-1.7268-2.4586-3.3235l.2163-1.4456 5.7821.865s-.0563.3754-.2163 1.4455Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M20 8.5C15 8.5 7.6 5.8 6 3"
    />
  </svg>
);
