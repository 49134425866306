import * as React from 'react';
export const CalendarGraphIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 4H4c-1.1046 0-2 .8954-2 2v12c0 1.1046.8954 2 2 2h14c1.1046 0 2-.8954 2-2V6c0-1.1046-.8954-2-2-2ZM6 2v3M16.0001 2v3M7 15v-1M11 15v-3M15.0001 14.9999v-5"
    />
  </svg>
);
