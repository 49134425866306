import * as React from 'react';
export const PizzaIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.4477 3.8979c.2605-.782-.4835-1.5256-1.2653-1.2647L2.8786 7.4068c-.4958.1654-.7838.686-.627 1.1845a15.65 15.65 0 0 0 10.2472 10.238c.4985.1563 1.0187-.1321 1.1838-.6278l4.7651-14.3036Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.61 6.47a16.9002 16.9002 0 0 0 9.85 9.7699M13.33 3.73a2.4994 2.4994 0 0 1 .4673 2.2513 2.501 2.501 0 0 1-1.5692 1.6808 2.4997 2.4997 0 0 1-3.0566-1.2028A2.5 2.5 0 0 1 8.89 5.3099"
    />
    <path
      stroke="currentColor"
      d="M13.39 10.81a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
);
