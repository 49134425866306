import * as React from 'react';
export const FrownCryfaceRejectedIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 20c4.9706 0 9-4.0294 9-9s-4.0294-9-9-9-9 4.0294-9 9 4.0294 9 9 9Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14 15c-1-2.5-5-2.5-6 0M6.5 8.9375c.7187.7501 1.8281.7501 2.5 0M13 8.9375c.7187.7501 1.8281.7501 2.5 0"
    />
  </svg>
);
