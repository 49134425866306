import * as React from 'react';
export const CoinsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={11}
      height={4}
      x={5}
      y={3}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={0.75}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.22 11H3.75a.75.75 0 0 1-.75-.75v-2.5A.75.75 0 0 1 3.75 7h9.5a.75.75 0 0 1 .75.75V10M13.25 19h-9.5a.75.75 0 0 1-.75-.75v-2.5a.75.75 0 0 1 .75-.75h6.345M12.5325 11H5.75a.75.75 0 0 0-.75.75v2.5a.75.75 0 0 0 .75.75h4.72"
    />
    <circle
      cx={15.25}
      cy={14.75}
      r={4.25}
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
