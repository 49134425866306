import * as React from 'react';
export const ForkArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.2593 5.68A9.0001 9.0001 0 0 0 3.3308 15.7099 8.9992 8.9992 0 0 0 10.9993 20v-5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.9993 9v3a3 3 0 0 0 6 0V9M10.9993 12V9M18.7792 3.29l.28 3.28-3.29-.55 3.01-2.73ZM14.9993 19.08a9 9 0 0 0 5-8.08v-.71"
    />
  </svg>
);
