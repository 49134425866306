import * as React from 'react';
export const SmallArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={13}
    fill="none"
    viewBox="0 0 8 13"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m1.5 11.5 5.0012-5.0012L1.5 1.4976"
    />
  </svg>
);
