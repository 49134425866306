import * as React from 'react';
export const BookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 15V2H5c-1.1046 0-2 .8954-2 2v13"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 17.5C3 16.1193 4.1193 15 5.5 15H19v5H5.5C4.1193 20 3 18.8807 3 17.5v0ZM10 2v7l2.5-2L15 9V2"
    />
  </svg>
);
