import * as React from 'react';
export const BellAlertsNotificationIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M6.413 8.9835C6.2396 6.3097 8.3205 4 11 4s4.7604 2.3097 4.587 4.9835C15.5353 9.7821 15.5 10.507 15.5 11c0 1.4836 1.0807 1.8809 1.821 3.1159C18.1729 15.5369 16.6569 17 15 17H7c-1.6569 0-3.1756-1.4653-2.317-2.8823C5.4233 12.8961 6.5 12.5051 6.5 11c0-.4931-.0352-1.2179-.087-2.0165Z"
    />
    <path
      fill="currentColor"
      d="M10 2c0-.5523.4477-1 1-1s1 .4477 1 1v2h-2V2ZM15 17c0 2.2091-1.7909 4-4 4-2.2091 0-4-1.7909-4-4h2c0 1.1046.8954 2 2 2s2-.8954 2-2h2Z"
    />
  </svg>
);
