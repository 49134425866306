import * as React from 'react';
export const LocationPinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 9c0-3.866 3.134-7 7.0001-7 3.866 0 7 3.134 7 7v.166"
    />
    <circle
      cx={11}
      cy={9}
      r={2}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 9c0 1.0932.1348 2.1916.555 3.1942.9568 2.2833 2.4468 4.3373 4.3653 6.0016l.7708.6687c.7513.6517 1.8672.6524 2.6192.0017l.7696-.6658c1.9114-1.6539 3.3974-3.6938 4.3547-5.9612.4289-1.0158.5655-2.1302.5655-3.2392"
    />
  </svg>
);
