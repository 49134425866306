import * as React from 'react';
export const MaskIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 11.1203V2c7.25 3.625 13.8869 1.1146 16 0v9.1203a7 7 0 0 1-3.29 5.936L11 20l-4.71-2.9437A7.0001 7.0001 0 0 1 3 11.1203Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.5 8.5c.7187-.7501 1.8281-.7501 2.5 0M13 8.5c.7187-.7501 1.8281-.7501 2.5 0M8.5673 13.5c1.3436 1.3436 3.522 1.3436 4.8657 0"
    />
  </svg>
);
