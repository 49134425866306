import * as React from 'react';
export const ShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.5 12.5981c-.8284-1.4349-.3368-3.2697 1.098-4.098 1.435-.8285 3.2697-.3369 4.0981 1.098.8285 1.4349.3369 3.2696-1.098 4.0981-1.4349.8284-3.2697.3368-4.0981-1.0981ZM18.5986 17.5005c.8284-1.4349.3368-3.2696-1.0981-4.0981-1.4349-.8284-3.2696-.3368-4.0981 1.0981-.8284 1.4349-.3368 3.2696 1.0981 4.0981 1.4349.8284 3.2697.3368 4.0981-1.0981ZM18.5986 7.5005c.8284-1.4349.3368-3.2696-1.0981-4.098-1.4349-.8285-3.2696-.3369-4.0981 1.098-.8284 1.4349-.3368 3.2697 1.0981 4.098 1.4349.8285 3.2697.3369 4.0981-1.098Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 13 13 15M13 7l-5 3"
    />
  </svg>
);
