import * as React from 'react';
export const PaperTearCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19 20-4-2-4 2-4-2-4 2V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v17Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8 10.25 2 2 4-4"
    />
  </svg>
);
