import * as React from 'react';
export const SoftwareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={18}
      height={18}
      x={2}
      y={2}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={2}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 10h17"
    />
    <circle cx={6} cy={6} r={0.5} stroke="currentColor" />
    <circle cx={9} cy={6} r={0.5} stroke="currentColor" />
    <circle cx={12} cy={6} r={0.5} stroke="currentColor" />
  </svg>
);
