import * as React from 'react';
export const HeartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.4466 11.2742C18.5 13.3834 15 16.748 11.0106 18.8586c-3.996-2.1277-7.5106-5.4724-8.436-7.5842C.295 6.072 5.2382.8717 9.6783 4.6536l1.3323 1.4206 1.3318-1.4209c4.4401-3.783 9.4183 1.4644 7.1042 6.6209Z"
    />
  </svg>
);
