import * as React from 'react';
export const SmallGreenCompleteCheckIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#00B683"
      fillOpacity={0.16}
      d="M8 15c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7Z"
    />
    <path
      stroke="#008660"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m11 6-3.5 4.5-2.5-2"
    />
  </svg>
);
