import * as React from 'react';
export const FolderIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 5a1 1 0 0 1-.6247-.2191L8.6971 3.4383 9.31 2.672l-.613.7662A2 2 0 0 0 7.4478 3H4c-1.1046 0-2 .8954-2 2v11c0 1.1046.8954 2 2 2h2v-7c0-1.1046.8954-2 2-2h10.5V7c0-1.1046-.8954-2-2-2H11Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M6 11v7h12c1.1046 0 2-.8954 2-2v-5c0-1.1046-.8954-2-2-2H8c-1.1046 0-2 .8954-2 2Z"
    />
  </svg>
);
