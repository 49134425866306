import * as React from 'react';
export const CloudDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.2112 16.7566a4.5077 4.5077 0 0 0 1.5402-.9009 4.5036 4.5036 0 0 0 .3606-6.3607 4.5081 4.5081 0 0 0-3.1073-1.4976 4.996 4.996 0 0 0-1.4645-3.5337A5.001 5.001 0 0 0 11.0047 3a5.0013 5.0013 0 0 0-3.5355 1.4637 4.9961 4.9961 0 0 0-1.4645 3.5337A4.5076 4.5076 0 0 0 2.8945 9.488a4.5029 4.5029 0 0 0 .3465 6.3607 4.5065 4.5065 0 0 0 1.549.908M11 13.0222v7.1875M7.8412 17.3858l3.1051 3.1052 3.2125-3.2125"
    />
  </svg>
);
