import * as React from 'react';
export const Airplane2Icon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 9h-4.89l-2.85-6.41A1.0003 1.0003 0 0 0 9.35 2H8v7H4.94L2 7v5a1.0001 1.0001 0 0 0 1 1h5v7h1.35a1.0004 1.0004 0 0 0 .91-.59L13.11 13H18a2 2 0 0 0 0-4Z"
    />
  </svg>
);
