import * as React from 'react';
export const TaxiIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 11H4c-1.1046 0-2 .8954-2 2v2c0 1.1046.8954 2 2 2h14c1.1045 0 2-.8954 2-2v-2c0-1.1046-.8955-2-2-2ZM5 18v2M17 18v2M5 14h1M16 14h1M16.6837 7.0513A2.9999 2.9999 0 0 0 13.8377 5H8.1623a3 3 0 0 0-2.8461 2.0513L4 11h14l-1.3163-3.9487ZM9 2h4"
    />
  </svg>
);
