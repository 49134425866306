import * as React from 'react';
export const ComputerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 17v3H3a1.0002 1.0002 0 0 1-1-1V3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v3"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 6H7c-.5523 0-1 .4477-1 1v8c0 .5523.4477 1 1 1h12c.5523 0 1-.4477 1-1V7c0-.5523-.4477-1-1-1ZM13 16v3M10 20h7M6 12h14"
    />
  </svg>
);
