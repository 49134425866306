import * as React from 'react';
export const DotsMiscIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 9c1.6568 0 3-1.3432 3-3C9 4.343 7.6568 3 6 3 4.343 3 3 4.343 3 6 3 7.6568 4.343 9 6 9ZM16 9c1.6568 0 3-1.3432 3-3 0-1.6569-1.3432-3-3-3-1.6569 0-3 1.3431-3 3 0 1.6568 1.3431 3 3 3ZM6 19c1.6568 0 3-1.3432 3-3 0-1.6569-1.3432-3-3-3-1.6569 0-3 1.3431-3 3 0 1.6568 1.3431 3 3 3ZM16 19c1.6568 0 3-1.3432 3-3 0-1.6569-1.3432-3-3-3-1.6569 0-3 1.3431-3 3 0 1.6568 1.3431 3 3 3Z"
    />
  </svg>
);
