import * as React from 'react';
export const LoopRight2Icon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m7.3835 1.8923 3.8031 2.1956L8.915 8.0224m6.3174-2.3445c3.4783 2.3604 4.3846 7.0937 2.0241 10.572-2.3604 3.4784-7.0937 4.3846-10.572 2.0242-3.4783-2.3605-4.3846-7.0937-2.0241-10.572 1.4737-2.1719 3.8725-3.341 6.3134-3.3382"
    />
  </svg>
);
