import * as React from 'react';
export const GiftBoxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 12h12v7a1.0001 1.0001 0 0 1-1 1H6a1.0002 1.0002 0 0 1-1-1v-7ZM18 7H4c-.5523 0-1 .4477-1 1v3c0 .5523.4477 1 1 1h14c.5523 0 1-.4477 1-1V8c0-.5523-.4477-1-1-1ZM8.5 2A2.5001 2.5001 0 0 1 11 4.5V7H8.5A2.5 2.5 0 0 1 6 4.5v0A2.5 2.5 0 0 1 8.5 2v0ZM13.5 7H11V4.5A2.5001 2.5001 0 0 1 13.5 2v0A2.5003 2.5003 0 0 1 16 4.5v0A2.5001 2.5001 0 0 1 13.5 7v0ZM11 7v12"
    />
  </svg>
);
