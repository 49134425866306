import * as React from 'react';
export const PersonDollarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 11v2M16 19v2"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 13h-2.5a1.5001 1.5001 0 0 0-1.5 1.5v0a1.5001 1.5001 0 0 0 1.5 1.5h1a1.5001 1.5001 0 0 1 1.5 1.5v0a1.5001 1.5001 0 0 1-1.5 1.5H14M10 10c2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4-2.2091 0-4 1.7909-4 4 0 2.2091 1.7909 4 4 4ZM10 16l-3-3h-.08A3.9201 3.9201 0 0 0 3 16.92V20h7"
    />
  </svg>
);
